.App {
  text-align: center;
}

.watermark {
  z-index: -1;
  position: fixed;
  opacity: 0.1;
  width: 20em;
  height: auto;
  bottom: 1em;
  right: 1em;
}

.login-form input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px var(--nhub-color1) inset;
  box-shadow: 0 0 0px 1000px var(--nhub-color1) inset;
  -webkit-text-fill-color: var(--login-form-text);
}
h4 {
  font-weight: 400;
  font-size: 18px;
}
h5 {
  color: var(--title-1);
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin: 10px 0;
}
/* MUI's Dialog title */
#alert-dialog-title{
  color: var(--title-1);
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin: 10px 0;
}
.menu-tab-bar {
  z-index: 1;
  position: relative;
  display: none;
  top: var(--header-height);
  color: var(--title-2);
  background-color: var(--main-background);
  width: 100%;
  padding-top: 1em;
  padding-bottom: 11px;
}
.menu-title {
  z-index: 1;
  position: fixed;
  top: var(--header-height);
  color: var(--title-2);
  background-color: var(--main-background);
  width: 100%;
  padding-top: 1em;
  padding-bottom: 15px;
  display: flex;
  justify-content: flex-start;

}
.menu-title h4 {
  margin: 0;
  color: var(--title-2);
}
.menu-title a {
  color: var(--title-1);
  text-decoration: none;
}
.menu-title a:visited {
  text-decoration: none;
}
.menu-title a:hover {
  text-decoration: none;
}
.menu-title a:active {
  text-decoration: none;
}
ul.menu-tab-items {
  list-style-type: none;
  margin: 10px;
  padding: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  white-space: nowrap;
}
ul.menu-tab-items li {
  display: none;
}
ul.menu-tab-items li a {
  color: var(--menu-tab-text);
  text-decoration: none;
  position: relative;
}
ul.menu-tab-items li a.active {
  color: var(--menu-tab-active-text);
  border-bottom: 1px solid var(--menu-tab-active-border);
  padding: 5px 0px;
}
ul.menu-tab-items li a:not(.active)::after {
  color: var(--menu-tab-hover-text);
  background: var(--nhub-color1);
  bottom: -6px;
  content: "";
  display: block;
  height: 1px;
  left: 50%;
  position: absolute;
  -webkit-transition: width 0.3s linear, left 0.3s linear;
  transition: width 0.3s linear, left 0.3s linear;
  width: 0;
}
ul.menu-tab-items li a:hover:not(.active)::after {
  color: var(--menu-tab-hover-text);
  height: 1px;
  left: 0;
  width: 100%;
  padding: 0px;
}
ul.menu-tab-items li a.inactive {
  color: var(--menu-tab-inactive-text);
  border-bottom: none;
  padding: 5px 0px;
  pointer-events: none;
}
ul.menu-tab-items li svg {
  color: var(--raw-opener);
  font-size: x-large;
  margin-left: 10px;
  margin-bottom: -5px;
}
.flexbox-summary {
  color: var(--content-text);
}
.widget {
  color: var(--content-text);
  background-color: var(--box-background);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid var(--box-border);
  height: 100%;
}
.widget h5 {
  margin: 0;
  font-weight: 600;
}
.widget-submit-btn {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
.summary-content {
  display: flex;
  justify-content: flex-start;
}
.summary-items {
  list-style: none;
  margin: 0;
  /* white-space: nowrap; */
}
li {
  min-height: 21px;
}
.summary-items li {
  line-height: 21px;
  margin: 5px 0;
  list-style-type: none;
}
.summary-items ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.summary-titles {
  text-align: right;
  padding-right: 0;
  vertical-align: middle;
  color: var(--colored-text);
  font-size: 14px;
  font-weight: 400;
}
.summary-values {
  text-align: left;
  padding-left: 0;
  margin-left: 20px;
  vertical-align: middle;
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
}
.external-links a {
  color: var(--web-link);
  text-decoration: none;
  font-size: 12px;
  font-weight: 700;
  /* margin-left: 5px; */
}
.external-links a:hover {
  text-decoration: underline 1px dashed;
}
.external-links {
  color: var(--web-link);
  text-decoration: none;
  font-size: 12px;
  font-weight: 700;
  margin-left: 5px;
}
.external-links :hover {
  text-decoration: underline 1px dashed;
  cursor: pointer;
}
/* --- MARKDOWN --- */

.invest-markdown {
  padding: 10px;
  font-size: 12px;
  font-weight: 400;
}
.invest-markdown h1,
.invest-markdown h2,
.invest-markdown h3,
.invest-markdown h4,
.invest-markdown h5,
.invest-markdown h6 {
  margin-bottom: 0;
  font-weight: 400;
  color: var(--title-1);
}
.invest-markdown h1 {
  font-size: large;
}
.invest-markdown h2 {
  font-size: medium;
}
.invest-markdown h3 {
  font-size: small;
}
.invest-markdown h4 {
  font-size: x-small;
}
.invest-markdown h5 {
  font-size: xx-small;
}
.invest-markdown p {
  margin-top: 0;
}
.invest-markdown a {
  color: var(--web-link);
  text-decoration: none;
  font-weight: 700;
}
.invest-markdown a:hover {
  text-decoration: underline 1px dashed;
}
.invest-markdown ul {
  padding-left: 25px;
  list-style-type: none;
}
.invest-markdown ul li:before {
  content: "⬣";
  color: var(--nhub-color-secondary-5);
  margin-right: 5px;
}

/* --- Responsive part --- */

* {
  box-sizing: border-box;
}

@media screen and (max-width: 768px) and (orientation: portrait) {
  ul.menu-tab-items {
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 14px;
  }

  ul.menu-tab-items li {
    margin: 5px 10px;
  }

  .menu-title {
    top: calc(var(--header-height) + 40px);
    position: inherit;
    background-color:inherit;
    padding-top: 0;
  }
  .widget {
    overflow: scroll;
  }

  h4 {
    font-size: 14px;
  }
  h5 {
    font-size: 10px;
  }
}
@media screen and (max-width: 1000px) and (orientation: landscape) {
  ul.menu-tab-items {
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 14px;
  }
  ul.menu-tab-items li {
    margin: 5px 10px;
  }
  .menu-title {
    position: inherit;
    background-color:inherit;
    padding-top: 0;
  }

  .widget {
    overflow: scroll;
  }

  h4 {
    font-size: 14px;
  }
}

@media screen and (min-width: 900px) {
  .menu-tab-bar {
    position: fixed;
    display: block;
  }
  ul.menu-tab-items li {
    display: block;
  }
}

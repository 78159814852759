.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  z-index: 1;
}

.navbar {
  position: fixed;
  top: 0;
  /* width: 80vw; */
  z-index: 5;
}
.navbar .menu-items {
  display: flex;
}

.navbar .nav-container li {
  list-style: none;
  width: 100%;
}

.navbar .nav-container a {
  text-decoration: none;
  color: var(--title-5);
  font-weight: 500;
  font-size: 1.2rem;
  padding: 0.7rem;
}

.menu-items li:active {
  background-color: var(--mainNav-button-hover);
}
.menu-items li a:active {
  font-weight: bolder;
}
.menu-items li .inactive {
  color: var(--menu-tab-inactive-text);
  pointer-events: none;
}

.nav-container {
  display: block;
  position: relative;
  /* height: 60px; */
}

.nav-container .checkbox {
  position: absolute;
  display: block;
  height: 32px;
  width: 32px;
  top: 15px;
  left: 0px;
  z-index: 6;
  opacity: 0;
  cursor: pointer;
}

.nav-container .hamburger-lines {
  display: block;
  height: 18px;
  width: 24px;
  position: absolute;
  top: 20px;
  left: 0px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.nav-container .hamburger-lines .line {
  display: block;
  height: 3px;
  width: 100%;
  border-radius: 10px;
  /* background: var(--nhub-color-border-2); */
  background: var(--header-button);
}

.nav-container .hamburger-lines .line1 {
  transform-origin: 0% 0%;
  transition: transform 0.4s ease-in-out;
}

.nav-container .hamburger-lines .line2 {
  transition: transform 0.2s ease-in-out;
}

.nav-container .hamburger-lines .line3 {
  transform-origin: 0% 100%;
  transition: transform 0.4s ease-in-out;
}

.navbar .menu-items {
  background-color: var(--mainNav-background);
  padding-top: 120px;
  height: 100vh;
  width: 75vw;
  transform: translate(-150%);
  display: flex;
  flex-direction: column;
  margin-left: -20px;
  padding-left: 0px;
  transition: transform 0.5s ease-in-out;
  text-align: center;
}

.navbar .menu-items li {
  margin-bottom: 1.2rem;
  font-size: 1.5rem;
  font-weight: 500;
}

.nav-container input[type="checkbox"]:checked ~ .menu-items {
  transform: translateX(0);
}
.opacDiv {
  position: fixed;
  top: 0;
  left: 100vw;
  width: 100vw;
  height: 100vh;
  z-index: 4;
  /* display: block; */
  background-color: rgba(0, 0, 0, 0.5);
  transition: transform 0.5s ease-in-out;
  /* visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 6s, visibility 6s;
  transition: opacity 6s, visibility 6s; */
}
.nav-container input[type="checkbox"]:checked ~ .opacDiv {
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* display: block; */
  /* left: 75vw; */
  transform: translateX(-25vw);
  /* visibility: visible;
  opacity: 0.5; */
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
  transform: rotate(45deg);
  background-color: var(--nhub-color-border-2);
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
  transform: scaleY(0);
  background-color: var(--nhub-color-border-2);
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
  transform: rotate(-45deg);
  background-color: var(--nhub-color-border-2);
}

/* .nav-container input[type="checkbox"]:checked ~ .logo {
  display: none;
} */

@media screen and (min-width: 900px) {
  .nav-container {
    display: none;
  }
}

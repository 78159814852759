html {
  height: 100%;

  --header-height: 3.5em;
  /* --MainNav-width: 162px;
  --MainNav-closed-width: calc(3em - 8px); */

  --loader: var(--nhub-color1);

  --header-background: var(--nhub-color1);
  --header-button-logo: var(--nhub-color-background-2);
  --header-button-title: var(--nhub-color-background-2);
  --header-button: var(--nhub-color-background-2);
  --header-button-hover: var(--nhub-color-secondary-2);
  --header-menu-background: var(--nhub-color-background-2);

  --login-shapes: var(--nhub-color1);
  --login-box: var(--nhub-color-background-7);
  --login-background: color-mix(in srgb, var(--nhub-color-background-7), transparent 25%);
  --login-button-color: var(--nhub-color1);
  --login-buttonHover-color: color-mix(in srgb, var(--nhub-color1) 70%, black);

  --mainNav-background: var(--nhub-color-background-2);
  --mainNav-text: var(--nhub-color2);
  --mainNav-text-active: var(--nhub-color-background-2);
  --mainNav-button: var(--nhub-color-background-2);
  --mainNav-button-hover: var(--nhub-color-background-5);
  --mainNav-button-active: var(--nhub-color1);

  --main-border: var(--nhub-color2);

  --table-th-background: var(--nhub-color1);
  --table-th-hover-background: var(--nhub-color1);
  --table-th-text: var(--nhub-color-background-1);
  --table-th-filter-active: var(--nhub-color-secondary-5);
  --table-tr-background: color-mix(in srgb, var(--nhub-color-background-1) 10%, white);
  --table-tr-nth--child-background: var(--nhub-color-background-6);
  --table-tr-hover-background: var(--nhub-color-background-5);
  --table-tr-text: var(--nhub-color2);
  --table-tr-link: var(--nhub-color1);
  --table-tr-link-hover: var(--nhub-color1);
  --table-tr-link-2: var(--nhub-color2);
  --table-tr-link-2-hover: var(--nhub-color-secondary-4);
  --table-tr-locked-text: color-mix(in srgb, var(--nhub-color2) 10%, white);
  --table-border: var(--nhub-color-background-5);

  --title-1: var(--nhub-color1);
  --title-2: var(--nhub-color2);
  --title-3: var(--nhub-color-secondary-4);
  --title-4: var(--nhub-color-background-1);
  --title-5: var(--nhub-color3);

  --widget-button: var(--nhub-color1);
  --widget-button-hover: var(--nhub-color-secondary-4);

  --main-background: var(--nhub-color-background-1);
  --box-background: var(--nhub-color-background-6);
  --box-background-2: var(--nhub-color1);
  --box-background-3: var(--nhub-color-secondary-6);
  --box-background-4: var(--nhub-color-secondary-5);
  --box-background-5: var(--nhub-color-secondary-4);
  --box-border: var(--nhub-color-border-1);

  --content-text: var(--nhub-color2);
  --colored-text: var(--nhub-color1);
  --tag-background: color-mix(in srgb, var(--nhub-color-secondary-5) 10%, white);
  --tag-border-color: var(--nhub-color-secondary-5);
  --tag-color: var(--nhub-color3);

  --menu-tab-text: var(--nhub-color2);
  --menu-tab-active-text: var(--nhub-color1);
  --menu-tab-active-border: var(--nhub-color1);
  --menu-tab-inactive-text: color-mix(in srgb, var(--nhub-color2) 35%, white);
  --menu-tab-hover-text: var(--nhub-color1);
  --menu-tab-hover-border: var(--nhub-color1);

  --raw-opener: var(--nhub-color-secondary-5);
  --raw-title-1: var(--nhub-color1);
  --raw-title-2: var(--nhub-color1);
  --raw-border: var(--nhub-color-background-4);
  --raw-dialog: color-mix(in srgb, var(--nhub-color1) 90%, white);

  --mitre-background-1: var(--nhub-color-border-1);
  --mitre-background-2: #fe0000e5;
  --mitre-row-1: #242424;

  --wiki-background-1: var(--nhub-color-background-2);
  --wiki-border-1: #48424242;

  --web-link: var(--nhub-color-secondary-5);

  --button-background-1: var(--nhub-color-background-3);
  --button-background-2: var(--nhub-color-secondary-1);
  --button-background-3: var(--nhub-color-secondary-3);
  --button-background-4: var(--nhub-color-secondary-2);
  --button-background-5: var(--nhub-color-secondary-4);
  --button-background-6: var(--nhub-color-secondary-5);
  --button-background-7: var(--nhub-color-secondary-6);
  --button-background-8: var(--nhub-color-secondary-7);
  --button-background-9: var(--nhub-color1);
  --button-background-hover-1: var(--nhub-color-background-4);
  --button-background-hover-2: var(--nhub-color-secondary-hover-1);
  --button-background-hover-3: var(--nhub-color-secondary-hover-3);
  --button-background-hover-4: var(--nhub-color-secondary-hover-2);
  --button-background-hover-5: var(--nhub-color-secondary-hover-4);
  --button-background-hover-6: var(--nhub-color-secondary-hover-5);
  --button-background-hover-7: var(--nhub-color-secondary-hover-6);
  --button-background-hover-8: var(--nhub-color-secondary-hover-7);
  --button-background-hover-9: var(--nhub-color1-hover);

  /* ----- Palette de couleurs ----- */

  --mui-error: #d32f2f;
  --nhub-color1: #005f93;
  --nhub-color2: #484242;
  --nhub-color3: #000000;
  --nhub-color-secondary-1: #d32f2f;
  --nhub-color-secondary-2: #f4a261;
  --nhub-color-secondary-3: #388e3c;
  --nhub-color-secondary-4: #db6550;
  --nhub-color-secondary-5: #2a9d8f;
  --nhub-color-secondary-6: #e9c46a;
  --nhub-color-secondary-7: #953403;
  --nhub-color-background-1: #ffffff;
  --nhub-color-background-2: #f2f2f2;
  --nhub-color-background-3: #dce8f4;
  --nhub-color-background-4: #cadaef;
  --nhub-color-background-5: #ddd;
  --nhub-color-background-6: #dedede60;
  --nhub-color-background-7: #1b4151;
  --nhub-color-border-1: #c4c4c4;
  --nhub-color-border-2: color-mix(in srgb, var(--nhub-color2) 30%, white);
  --nhub-color-border-3: #dedede;
  --nhub-color1-hover: color-mix(in srgb, var(--nhub-color1) 70%, black);
  --nhub-color-secondary-hover-1: color-mix(in srgb, var(--nhub-color-secondary-1) 70%, black);
  --nhub-color-secondary-hover-2: color-mix(in srgb, var(--nhub-color-secondary-2) 70%, black);
  --nhub-color-secondary-hover-3: color-mix(in srgb, var(--nhub-color-secondary-3) 70%, black);
  --nhub-color-secondary-hover-4: color-mix(in srgb, var(--nhub-color-secondary-4) 70%, black);
  --nhub-color-secondary-hover-5: color-mix(in srgb, var(--nhub-color-secondary-5) 70%, black);
  --nhub-color-secondary-hover-6: color-mix(in srgb, var(--nhub-color-secondary-6) 70%, black);
  --nhub-color-secondary-hover-7: color-mix(in srgb, var(--nhub-color-secondary-7) 70%, black);
  --nhub-color-secondary-hover-8: color-mix(in srgb, var(--nhub-color-secondary-8) 70%, black);
}
body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: '#496870';
}
body,
input,
button,
textarea,
label,
h1,
h2,
h3,
h4,
h5,
h6,
a {
  font-family: "Poppins", sans-serif !important;
}
h1,h2,h3,h4,h5,h6 {color: var(--nhub-color1);}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#mainContent {
  margin-top: var(--header-height);
  margin-left: var(--MainNav-width);
  padding: 20px 20px 0px 20px;
  /* transition: margin-left 0.3s ease-out; */
}
#root {
  margin: 0;
}

/* @media screen and (max-width: 768px) and (orientation: portrait)
{
  #mainContent {
    margin-top: calc(var(--header-height) + 30px);
    --MainNav-width: 0px;
    --MainNav-closed-width: 0px;
  }
}

@media screen and (max-width: 1000px) and (orientation: landscape)
{
  #mainContent {
    --MainNav-closed-width: calc(3em - 8px);
    --MainNav-width: var(--MainNav-closed-width);
  }
} */

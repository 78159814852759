.login-page {
  /* background-image: url("../assets/images/loginPageImage.webp"); */
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: top center;
  min-height: 100vh;
  min-width: 100vw;
  color: var(--title-4);
  position: absolute;
}
.bg-fade {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: opacity 0.2s ease;
}

.login-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 11vh;
}
.login-page h1 {
  color: var(--title-4);
  font-size: 10vw;
  text-align: center;
}
.login-banner {
  width: 100vw;
}
.login-logo {
  height: auto;
  width: 100vw;
  padding: 45px 5px;
  filter: drop-shadow(3px 0px 12px rgb(0, 0, 0));
}
.login-logo .st0:hover {
  filter: drop-shadow(-3px -3px 10px rgb(0, 0, 0));
}
.login-logo .st1:hover {
  filter: drop-shadow(-5px -3px 2px rgb(0 0 0 / 0.4));
}

.login-select-language {
  position: absolute;
  top: 10px;
  right: 10px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.login-form {
  background-color: var(--login-background);
  background-color: #1b4151;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.login-form-titles {
  color: var(--nhub-color-text-3);
  font-size: 16px;
  font-weight: 500;
}
.login-form-titles2 {
  color: #1b4151;
  /* filter: drop-shadow(0 1px 1px rgb(0 0 0 / 0.1)); */
  font-weight: 500;
}
.login-form-subtitles {
  font-size: 14px;
}

.login-form-subContainer {
  display: flex;
  padding: 15px;
  width: 100vw;
}
.login-form-subContainer1 {
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #1b4151;
}
.login-form-subContainer2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
}

/* Register from */

.register-form {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.register-titles {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.register-form-titles {
  margin-bottom: 10px;
}
.register-buttons-container {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.register-buttons-container span {
  font-size: 12px;
}

.passwordTooltip-container {
  position: relative;
}
.passwordTooltip ul {
  list-style-type: none;
  margin-top: 0;
}
.passwordTooltip {
  display: flex;
  position: absolute;
  z-index: 10;
  right: 5%;
  left: 5%;
  border: 1px solid var(--box-border);
  border-top: none;
  border-radius: 0px 0px 50px 50px;
  background-color: var(--login-box);
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.passwordTooltip h5 {
  font-weight: 600;
  color: var(--title-4);
}
.passwordTooltip svg {
  vertical-align: middle;
  width: 21px;
  height: 21px;
}

@media screen and (min-width: 350px) {
  .login-logo {
    padding: 45px;
  }
  .login-form-titles2 {
    margin-right: 20px;
  }
}
@media screen and (min-width: 400px) {
  .login-form-titles {
    font-size: 20px;
  }
  .login-form-subtitles {
    font-size: 16px;
  }
}
@media screen and (min-width: 600px) {
  .login-form-subContainer {
    width: auto;
    border-radius: 15px;
  }
}

@media screen and (min-width: 700px) {
  /* .login-page{
    background-image: url('../assets/images/loginPageImage.png');
  } */
  .login-form {
    width: 50%;
    min-height: 45vh;
    border-radius: 50px;
  }
  .login-logo {
    height: 12.5em;
    width: auto;
    padding: 45px;
  }
  .login-page h1 {
    font-size: 60px;
  }
}

@media screen and (min-width: 900px) {
  .register-form {
    width: 40vw;
  }
  .register-buttons-container {
    width: 40vw;
    margin-top: 20px;
  }
}

@media screen and (min-width: 1200px) {
  .login-select-language {
    right: 10px;
  }
}

.nhub-header {
  background: var(--header-background);
  position: fixed;
  top: 0;
  width: 100%;
  height: var(--header-height);
  color: var(--header-button);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 5;
}
.nhub-header a {
  display: flex;
  align-items: flex-end;
  text-decoration: none;
  /* text-transform: capitalize; */
  color: var(--header-button-title);
  font-weight: 600;
}
.nhub-header-text {
  margin-left: 9px;
}
.nhub-header a:link {
  text-decoration: none;
}
.nhub-header a:visited {
  text-decoration: none;
}
.nhub-header a:hover {
  text-decoration: none;
}
.nhub-header a:active {
  text-decoration: none;
}

.nhub-title {
  color: var(--header-button-title);
}
.nhub-logo {
  padding-left: 20px;
  height: 2em;
  width: auto;
}

.st0 {
  fill: var(--header-button-logo);
}
/* .st0:hover{fill: var(--nhub-color2);} */
.st1 {
  fill: var(--header-button-logo);
}
/* .st1:hover{fill:#0067EE;} */

.header-nav {
  margin-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-nav span{
  margin-left: 6px;
  font-size: x-large;
  color: var(--header-button);
}
/* .header-nav img{
  height: 20px;
  width: auto;
} */
.header-nav span :hover:not(.active) {
  color: var(--header-button-hover);
}
.header-nav-link {
  cursor: pointer;
}
.header-nav-release {
  right: 10px;
  top: 100%;
  position: absolute;
  background-color: var(--header-menu-background);
  border-collapse: collapse;
  overflow: auto;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  border-radius: 0 0 5px 5px;
  border: 1px solid var(--box-border);
  max-height: 70vh;
}
.header-nav-menu-section {
  border-top: 1px solid var(--nhub-color-border-3);
}
.section-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  width: 100%;
}
.release-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  width: 100%;
}
.release-title h5 {
  text-align: center;
  width: 100%;
}
.notification-items {
  padding: 5px 10px;
  color: var(--nhub-color3);
  font-size: 12px;
  font-weight: 400;
}
.notification-items span {
  margin-right: 10px;
  font-size: x-large;
  color: var(--title-1);
}
.notification-items span :hover:not(.active) {
  color: var(--title-1);
}
.notification-items a {
  color: var(--title-1);
}
.notification-count {
  background: var(--header-background);
  border-radius: 50%;
  color: var(--nhub-color-background-1);
  font-weight: 600;
  padding: 2px;
  text-align: center;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  margin-right: 10px;
}

.select-language {
  /* position: absolute;
  top: 10px;
  right: 35px; */
  margin-top: -5px;
  padding-right: 15px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.select-language-img {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(calc(-50% - 0.5px), calc(-50% - 0.5px));
}

.select-language ul {
  padding: 0;
  display: flex;
  /* transform: translate(-50%, -50%); */
}

.select-language ul li {
  list-style: none;
  margin-left: 10px;
}

.select-language ul li button {
  position: relative;
  display: block;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 63px;
  background: #333;
  border-radius: 50%;
  font-size: 30px;
  color: #666;
  transition: 0.5s;
  border: none;
  cursor: pointer;
  padding: 0;
}

.select-language ul li button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: var(--nhub-color-background-2);
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.languageButtonIsActive::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: var(--nhub-color-background-2);
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}
.languageButtonIsActive::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px var(--nhub-color-background-2);
}
.languageButtonIsActive {
  color: var(--nhub-color-background-2);
  box-shadow: 0 0 5px var(--nhub-color-background-2);
  text-shadow: 0 0 5px var(--nhub-color-background-2);
  transition: all 0.65s;
  transform: rotateY(360deg);
}

@media screen and (max-width: 550px) {
  .nhub-logo {
    width: 40vw;
  }
  .nhub-header-text{display: none;}
  .select-language{display: none;}
  .login-select-language{display: flex;}
  .header-nav button{display: none;}
}

@media screen and (max-width: 900px) {
  .nhub-logo {
    padding-left: 55px;
  }
}
.nhub-home-container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.nhub-home-container h2 {
  font-size: 24px;
  font-weight: 400;
}
.home-item {
  display: flex;
  flex-direction: row;
  gap: 15px;
}
.home-item-content {
  text-align: left;
}
.home-item-content p {
  font-size: 12px;
}
.home-item svg {
  font-size: 4em;
}

@media screen and (min-width: 600px) {
  .home-item svg {
    font-size: 6em;
  }
}

/* ---- HISTORY ----- */

.history-container {
  max-height: calc(100vh - 460px);
  overflow-y: auto;
  width: 100%;
}
.history-box {
  margin: 10px 10px;
  position: relative;
}
.avatar {
  height: 40px;
  width: 46px;
  text-align: center;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background-color: var(--nhub-color1);
  color: var(--title-4);
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  font-weight: 700;
  font-size: 18px;
}
.avatarSkeleton {background-color: transparent;}
.avatar img {
  height: 30px;
  width: 30px;
  text-align: center;
}

.history-user {
  /* position: absolute; */
  /* padding-left: 60px; */
  /* width: 15em; */
  color: var(--title-1);
  text-align: left;
  font-size: 12px;
  font-weight: 400;
}
.history-subject {
  /* position: absolute; */
  /* padding-left: 60px; */
  /* width: 15em; */
  /* color: var(--title-1); */
  text-align: left;
  font-size: 12px;
  font-weight: 400;
}
.history-description {
  /* position: absolute; */
  /* left: 15em; */
  /* right: 50px; */
  /* padding: 0 60px 0 60px; */
  /* margin: 0; */
  text-align: justify;
  font-size: 12px;
  font-weight: 400;
}
.history-date {
  /* position: absolute; */
  /* right: 10px; */
  /* width: 100px; */
  /* text-align: right; */

  color: var(--title-1);
  font-size: 12px;
  font-weight: 400;
}

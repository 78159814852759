.tableFixHead {
  width: 100%;
  overflow-y: auto;
}
.alert-table {
  height: calc(100vh - 95px);
}
.invest-table {
  height: calc(100vh - 225px);
}
.report-table {
  max-height: calc(100vh - 515px);
}
.tableFixHead table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid var(--table-border);
  border-top: none;
}
a:hover {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}

.tableFixHead thead {
  position: sticky;
  top: 0;
}
.tableFixHead td,
.tableFixHead th {
  border-bottom: 1px solid var(--table-border);
  padding: 8px;
  width: 10px;
  -webkit-transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  white-space: nowrap;
}
.tableFixHead a {
  text-decoration: none;
  color: var(--table-tr-link);
}
.tableFixHead a:hover {
  color: var(--table-tr-link-hover);
}
.tableFixHead tr {
  background-color: var(--table-tr-background);
  font-size: 12px;
  font-weight: 400;
}
.tableFixHead tr:nth-child(even) {
  background-color: var(--table-tr-nth--child-background);
}
.tableFixHead tr:hover {
  background-color: var(--table-tr-hover-background);
}
.tableFixHead th {
  text-align: left;
  background-color: var(--table-th-background);
  color: var(--table-th-text);
  font-size: 16px;
  font-weight: 400;
}

.tableFixHead td:not(:hover) form {
  display: none;
}
.tableFixHead button {
  color: var(--table-tr-link);
  border: none;
  background: none;
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
}
.row-title form {
  color: var(--table-tr-link-2);
}
.row-title form a:link {
  text-decoration: none;
  font-size: large;
  color: var(--table-tr-link-2);
}
.row-title form a:visited {
  text-decoration: none;
  color: var(--table-tr-link-2);
}
.row-title form a:hover {
  text-decoration: none;
  color: var(--table-tr-link-2-hover);
  font-size: x-large;
}
.row-title form a:active {
  text-decoration: none;
  color: var(--table-tr-link-2);
}
.locked {
  color: var(--table-tr-locked-text);
  cursor: pointer;
  font-style: italic;
}
.filter-opener {
  z-index: 1;
  cursor: pointer;
  color: var(--table-th-text);
  font-size: large;
  position: relative;
  height: 34px;
}
.filter-opener svg {
  color: var(--table-th-text);
  font-size: large;
}
.th-div {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sort-span {
  font-size: large;
  right: 10px;
  display: flex;
}
.filter-container {
  position: absolute;
  right: 10px;
  top: 34px;
  padding: 4px;
  background-color: var(--table-th-hover-background);
  color: var(--table-th-text);
  box-sizing: border-box;
  border-radius: 5px 5px;
  border-style: solid;
  border-width: 1px;
  border-color: var(--table-th-hover-background);
}
.filter-container input {
  color: var(--table-th-text);
}

@media screen and (max-width: 768px) and (orientation: portrait)
{
  .tableFixHead, .tableFixHead table  {
    width: 90vw;
  }
  .investBox-alert-table td {
    white-space: pre-line;
  }
}
@media screen and (max-width: 1000px) and (orientation: landscape)
{
  .invest-table {
    height: calc(100vh - 150px);
  }
  
}
.licenseItem-container {
  display: flex;
  flex-direction: row;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  margin: 10px;
  width: 100%;
  text-decoration: none;
}

.licenseItem-identity {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.licenseItem-logo {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-radius: 5px;
  width: 20vw;
  height: 20vw;
}
.licenseItem-logo img {
  width: inherit;
  height: inherit;
  object-fit: cover;
  border-radius: 5px;
}

.licenseItem-contentCenter {
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.licenseItem-contentCenter h1 {
  font-size: 18px;
  font-weight: 600;
  color: var(--title-1);
  margin: 10px;
}
.licenseItem-contentCenter p {
  text-align: justify;
}
.licenseItem-contentCenter button  {
  margin: 0;
}


.licenseItem-status {
  margin: 5px 10px;
  padding: 5px;
  border-radius: 5px;
  font-weight: 500;
  background-color: white;
  border: 2px solid var(--nhub-color-secondary-5);
  color: var(--nhub-color-secondary-5);
  text-transform: uppercase;
  white-space: nowrap;
  line-height: normal;
}

.licenseItem-status.not-started {
  border: 2px solid var(--nhub-color-secondary-6);
  color: var(--nhub-color-secondary-6);
}
.licenseItem-status.inProgress {
  border: 2px solid var(--nhub-color-secondary-2);
  color: var(--nhub-color-secondary-2);
}
.licenseItem-status.failed {
  border: 2px solid var(--nhub-color-secondary-4);
  color: var(--nhub-color-secondary-4);
}
.licenseItem-status.pending {
  border: 2px solid var(--nhub-color2);
  color: var(--nhub-color2);
  opacity: 0.4;
}
.licenseItem-statusDates {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  align-items: center;
  min-height: fit-content;
  margin-bottom: 10px;
}

.licenseItem-dates {
  white-space: nowrap;
}
.licenseItem-sectionTitle {
  white-space: nowrap;
}

.licenseItem-bounties {
  padding: 10px;
  border: 2px solid var(--nhub-color-secondary-2);
  border-radius: 10px;
  min-width: 200px;
}
.licenseItem-bounties h3 {
  text-align: center;
  margin: 0px;
  font-size: 14px;
  color: var(--nhub-color-secondary-2);
}

.licenseItem-bounties span {
  font-size: 26px;
  text-align: center;
  white-space: nowrap;
  width: 100%;
  display: block;
}

@media screen and (max-width: 1000px) {
  .licenseItem-container {
    flex-direction: column;
  }
  .licenseItem-bounties span {
    white-space: wrap;
    font-size: 15px;
  }

  .licenseItem-bounties {
    min-width: 0;
  }
}

@media screen and (max-width: 650px) {
  .licenseItem-identity {
    flex-direction: column;
    align-items: center;
  }
  .licenseItem-logo {
    width: 50vw;
    height: 50vw;
  }
  .licenseItem-logo img {
    object-fit: cover;
  }
}

@media screen and (max-width: 450px) {
  .licenseItem-statusDates{
    gap: 10px;
    flex-wrap: wrap;
  }
  .licenseItem-status {
    margin: 10px 0;
  }
}
